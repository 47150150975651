<template>
    <div>
        <styled-tooltip
            open-delay="0"
            close-delay="0"
            nudge-top="10"
            position="top">
            <template #content>
                <div class="tooltip-title">
                    Template Overview
                </div>
                <div class="tooltip-subtitle">
                    {{ itemTitle }}
                </div>
                <ol>
                    <li
                        v-for="(title, i) in pageTitles"
                        :key="i">
                        {{ title }}
                    </li>
                </ol>
            </template>
            <span class="page-num">{{ item.page_count }}</span>
        </styled-tooltip>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';

export default {
    components: {
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    computed: {
        itemTitle() {
            return this.item.display_name;
        },
        pageTitles() {
            const titles = [];
            this.item.configuration.pages.forEach(p => {
                titles.push(p.title);
            });
            return titles;
        }
    }
};
</script>

<style lang="scss" scoped>
.page-num {
    color: $blue-bg;
    text-decoration: underline;
    cursor: pointer;
}
.tooltip-title {
    color: $blue-bg;
    font-size: 14px;
}
.tooltip-subtitle {
    color: #8F9EA6;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 15px;
}
ol {
    color: #8F9EA6;
}
</style>